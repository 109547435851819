import * as React from "react";
import Layout, { AppStateContext } from "../components/Layout/Layout";
import parse from "html-react-parser";
import BasketItem from "../components/BasketItem";
import { injectIntl, Link } from "gatsby-plugin-intl";
import BreadCrumbs from "../BreadCrumbs";
//@ts-ignore
import Trash from "../images/trash_blue.svg";
import { useQuery } from "@apollo/client";
import { GET_ANALYSIS_BY_TYPE } from "../query/getAnalysis";
import {
  localStorageGetBasket,
  localStorageGetBasketCount,
  localStorageRemoveAnalysisFromBasket,
  localStorageResetBasket,
} from "../service/storage";
import { moneyFormatFromNumber } from "../utils/formatter";
import { useRef, useState } from "react";
import { navigate } from "gatsby-plugin-intl";

//@ts-ignore
const basket = ({ intl, pageContext }) => {
  const [analyses, setAnalyses] = useState([]);
  const [hideReset, setHideReset] = useState(true);

  const localBasket = useRef(localStorageGetBasket());

  if (localBasket.current.analyses.length > 0) {
    const variables = {
      filters: {
        id: {
          in: localBasket.current.analyses,
        },
      },
      pagination: {
        pageSize: 20,
      },
      locale: intl.locale,
    };

    const { loading, error } = useQuery(GET_ANALYSIS_BY_TYPE, {
      variables,
      onCompleted: (data) => {
        setAnalyses(data.analyses.data);
        setHideReset(data.analyses.data.length === 0);
        localBasket.current.analyses
          .filter(
            (analysisId: any) =>
              !data.analyses.data.find(({ id }) => analysisId === id)
          )
          .map((analysisId: any) =>
            localStorageRemoveAnalysisFromBasket(analysisId)
          );
      },
    });

    if (loading) return "";

    if (error) return `Error! ${error.message}`;
  }

  const dropAnalysis = (analysisId: any) => {
    const newAnalyses = analyses.filter(
      (analysis) => analysis.id !== analysisId
    );
    setAnalyses(newAnalyses);
    setHideReset(newAnalyses.length === 0);
  };

  return (
    <Layout>
      <div className="grid xs:justify-center sm:pl-0 bg-[#FAFAFA] pb-[10rem]">
        <div className=" flex flex-col sm:grid-rows-2 xs:grid-rows-3 justify-start xl:max-w-[1200px] xl:w-[1200px] lg:max-w-[1000px] lg:w-[1000px] sm:max-w-[680px] sm:w-[680px] xs:max-w-[300px] xs:w-[300px] sm:mb-[5rem] xs:mb-[2rem] lg:pl-[0rem] ">
          <div className="ml-[0.2rem] mt-[2rem] mb-[2rem] ">
            <BreadCrumbs pageContext={pageContext} title={"Header.Basket"} />
          </div>
          <h2 className=" text-[2rem] font-semibold leading-5">
            {parse(
              intl.formatMessage({
                id: "Header.Basket",
              })
            )}
          </h2>
          {analyses.length !== 0 ? (
            <div className="mt-[2rem] flex lg:flex-row xs:flex-col w-auto  rounded-[1rem] bg-white border border-[#618EE4] border-opacity-25">
              <div className="lg:w-[70%] pl-[1rem] pr-[0.5rem] pt-[1.5rem]  h-[100%] ">
                {analyses.map((analysis: any, index: any) => (
                  <BasketItem
                    key={`basket-item-${index}`}
                    analysis={analysis}
                    dropAnalysis={dropAnalysis}
                  />
                ))}
                {!hideReset ? (
                  <AppStateContext.Consumer>
                    {({ setBasketCount }) => (
                      <button
                        onClick={() => {
                          setBasketCount(0);
                          localStorageResetBasket();
                          setHideReset(true);
                          setAnalyses([]);
                        }}
                        className="mb-[4rem] flex items-center justify-center gap-2 mt-[1.5rem] w-[13.8rem] h-[2.5rem] text-[1rem] rounded-[0.56rem] text-[#618EE4] font-semibold border border-[#618EE4]"
                      >
                        <img src={Trash} alt="" className="" />
                        {parse(
                          intl.formatMessage({
                            id: "Header.DeleteTrash",
                          })
                        )}
                      </button>
                    )}
                  </AppStateContext.Consumer>
                ) : null}
              </div>
              <div className="border lg:border-l-[#618EE4] lg:border-r-white xs:border-t-[#618EE4] xs:border-b-white xs:border-opacity-25 lg:border-opacity-25 h-auto "></div>
              <div className="lg:w-[30%] lg:pt-[1.5rem] xs:pt-[1.2rem] xs:pb-[1.1875rem] lg:pl-[2rem] xs:pl-[1rem]">
                <div className="flex flex-col ">
                  <h2 className="mt-[1rem] font-semibold sm:text-[1.75rem] xs:text-[1.6rem] text-[#001439]">
                    {intl.formatMessage({ id: "ServiceMenu.HouseCall.Total" })}
                  </h2>
                  <h2 className="mt-[1rem]  font-semibold text-[1.75rem] text-[#001439]">
                    {moneyFormatFromNumber(
                      analyses.reduce(
                        (acc: any, analysis: any) =>
                          acc + analysis.attributes.price,
                        0
                      )
                    )}{" "}
                    ₸
                  </h2>
                </div>
                <AppStateContext.Consumer>
                  {({ profile, setShowLoginPopup }) => (
                    <button
                      onClick={() =>
                        profile
                          ? navigate("/application-form")
                          : setShowLoginPopup(true)
                      }
                      className="mt-[1.5rem] w-[13.8rem] h-[3rem] text-[1.25rem] rounded-[0.56rem] text-white font-semibold bg-[#618EE4]"
                    >
                      {parse(
                        intl.formatMessage({
                          id: "ServiceMenu.HouseCall.ApplyButton",
                        })
                      )}
                    </button>
                  )}
                </AppStateContext.Consumer>
              </div>
            </div>
          ) : (
            <div className="mt-[2rem] flex lg:flex-row xs:flex-col w-auto  rounded-[1rem] bg-white border border-[#618EE4] border-opacity-25">
              <div className="flex pl-[1rem] items-center lg:w-[70%]">
                <p className="text-center">
                  {parse(
                    intl.formatMessage({
                      id: "ServiceMenu.HouseCall.EmptyBasket",
                    })
                  )}
                </p>
              </div>
              <div className="border lg:border-l-[#618EE4] lg:border-r-white xs:border-t-[#618EE4] xs:border-b-white xs:border-opacity-25 lg:border-opacity-25 h-auto "></div>
              <div className="flex align-middle justify-center lg:w-[30%] py-6">
                <Link to="/analysis-and-prices">
                  <button className=" w-[13.8rem] h-[3rem] text-[1.25rem] rounded-[0.56rem] text-white font-semibold bg-[#618EE4]">
                    {parse(
                      intl.formatMessage({
                        id: "ServiceMenu.HouseCall.ChooseAnalysis",
                      })
                    )}
                  </button>
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default injectIntl(basket);
