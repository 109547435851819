import * as React from "react";

import { injectIntl } from "gatsby-plugin-intl";
import { moneyFormatFromNumber } from "../utils/formatter";
import {
  localStorageGetBasketCount,
  localStorageRemoveAnalysisFromBasket,
} from "../service/storage";
import { useContext } from "react";
import { AppStateContext } from "./Layout/Layout";

// @ts-ignore
const BasketItem = ({ intl, analysis, dropAnalysis }) => {
  // @ts-ignore
  const { setBasketCount } = useContext(AppStateContext);
  const handleRemoveItem = () => {
    localStorageRemoveAnalysisFromBasket(analysis.id);
    setBasketCount(localStorageGetBasketCount());
    dropAnalysis(analysis.id);
  };

  return (
    <div className="py-[1.5rem] pl-[0.5rem] flex sm:flex-row xs:flex-col  sm:gap-10 xs:gap-3  justify-between border border-b-[#618EE4] border-opacity-25 border-x-white border-t-white border-dotted">
      <p className="sm:w-[60%] xs:w-[100%] font-medium text-[1rem]">
        {analysis.attributes.title}
      </p>
      <div className="flex sm:w-[40%] xs:w-[100%] ">
        <h4 className="w-[50%] text-[#001439] text-opacity-75 font-medium text-[1.5rem]">
          {moneyFormatFromNumber(analysis.attributes.price)} ₸
        </h4>
        <button
          onClick={handleRemoveItem}
          className="cursor-pointer flex items-center justify-center border border-black w-[6.3rem] h-[2.75rem] rounded-[0.56rem]"
        >
          <p className="font-medium text-[1rem]">Удалить</p>
        </button>
      </div>
    </div>
  );
};

export default injectIntl(BasketItem);
